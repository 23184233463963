.footer {
  padding: 1.5em;
}

.footer__left,
.footer__right {
  color: #fff;
  opacity: 0.95;
}

.footer__right {
  margin-top: 2em;
}

.footer__h4 {
  margin-bottom: 1em;
}

.footer__right ul li a {
  padding: 0.5em 0;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 2em;
  }

  .footer__left {
    max-width: 400px;
  }

  .footer__left > h1 {
    font-size: 1.5rem;
    margin-bottom: 0.6em;
    font-family: Gilroy Bold;
  }

  .footer__right {
    margin-top: 0;
  }
}

@media only screen and (min-width: 900px) {
  .footer {
    padding: 2em 4em;
  }
}

@media only screen and (min-width: 1200px) {
  .footer {
    padding: 4em 10%;
  }
}
