* {
  box-sizing: border-box; }

*,
*::after,
*::before {
  scroll-behavior: smooth; }

html,
body {
  margin: 0;
  padding: 0;
  line-height: 1.8em; }

body {
  font-family: 'Helvetica Neue';
  line-height: 1.35;
  font-size: 1rem; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0; }

h1 {
  font-size: 1rem; }

h2 {
  font-size: 1rem; }

table {
  padding: 1em; }

td {
  padding: 0.6em; }

.text {
  font-size: 1rem; }

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer; }

ul {
  margin: 0;
  list-style: none; }

p {
  font-size: 1rem; }

button {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.5em;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px; }

input {
  outline: none;
  width: 100%;
  border: none;
  padding: 0.8em 1em; }

select {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  border: none;
  color: #818181;
  padding: 0.7em 1em;
  margin: 0.4em 0; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

table {
  width: 100%;
  border-collapse: collapse;
  color: #ffffff;
  opacity: 0.8; }

.number {
  font-size: 3rem;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  color: #ffffff; }

.gradient-bg {
  background-image: linear-gradient(to right, #2612b2, #230b88); }

.input {
  margin: 1em 0; }

.input input {
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px; }
