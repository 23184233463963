.signup {
  &__formContainer {
    max-width: 400px;
    margin: 2em auto;
    padding: 1.5em;
  }

  &__title {
    h2 {
      font-size: 1.3rem;
      font-weight: 800;
    }
  }

  &__socials {
    display: flex;
    justify-content: space-between;
    margin: 1.5em 0;
  }

  &__facebook,
  &__google {
    border: 1px solid rgb(228, 228, 228);
    padding: 0.5em 1.5em;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }

  &__loginLink {
    a {
      display: inline;
    }
  }

  &__ctaBtn {
    button {
      color: rgb(236, 236, 236);
      padding: 0.8em 1em;
      background-color: #2612b2;
      font-size: 0.9rem;
    }
  }

  @media only screen and (min-width: 760px) {
    &__formContainer {
      margin: 4em auto;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
    }

    &__title {
      margin: 2em 0;
    }
  }
}
