$white: #fff;
ul {
  padding: 0;
}

ul li a {
  display: block;
  padding: 1.5em 0;
}

.navigation__ctaBtns {
  button {
    background-color: #fff;
  }

  button + button {
    margin-left: 1em;
  }
}

.gradient-bg {
  background-image: linear-gradient(to right, #2612b2, #230b88);
}

.navigation__ctaBtns {
    a {
      display: block;
      background-color: $white;
      padding: 0.6em 0.5em;
      margin: 1em 0;
      text-align: center;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      
    }

    
}

.navigation__company {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation__company p {
  color: #fff;
  font-weight: 900;
  font-size: 1.5rem;
  font-family: "Gilroy Bold";
}

.navigation__hamburger {
  width: 42px;
  height: 30px;
  position: relative;
  cursor: pointer;
}

.navigation__checkbox {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

input.navigation__checkbox {
  width: 100%;
  height: 100%;
  border: 1px solid red;
}

input[type="checkbox"]:checked ~ .navigation__hamburgerItem {
  color: red;
  background-color: red;
  display: block;
}

.navigation {
  position: relative;
}

.navigation__hamburgerItem {
  width: 30px;
  height: 2px;
  margin: 5px;
  background-color: #fff;
}

.navigation__navItems {
  color: #fff;
}

.navigation__navItems ul li a {
  padding: 0.7em 0;
  cursor: pointer;
  max-width: 100%;
}

.navigation__ctaBtns,
.navigation__navItems {
  z-index: 1;
}

.navigation__mobileNav {
  min-width: 100vw;
  position: absolute;
  background-image: linear-gradient(to right, #2612b2, #230b88);
  z-index: 10;
  padding: 1em 1em 1em 1.5em;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  margin: 0px 0 0 -24px;
}

@media only screen and (min-width: 900px) {
  .navigation {
    // padding: 2em 0;
  }
}

@media only screen and (min-width: 1200px) {
  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navigation__hamburger {
    display: none;
  }

  .navigation__navItems,
  .navigation__ctaBtns {
    display: flex;
    position: relative;
  }

  .navigation__ctaBtns {
    a {
      padding: 0.3em 0.5em;
    }
    a + a {
      margin-left: 1em;
    }
  }

  .navigation__navItems nav ul {
    display: flex;
  }

  .navigation__navItems nav ul li a {
    padding: 0 1.5em;
  }
}
