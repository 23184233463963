.signin {
  &__siginContainer {
    max-width: 400px;
    margin: 2em auto;
    padding: 1.5em;
  }

  &__title {
    h2 {
      font-size: 1rem;
      font-weight: 9000;
    }
  }

  &__socials {
    display: flex;
    justify-content: space-between;
    margin: 2em 0;
  }

  &__facebook,
  &__google {
    border: 1px solid rgb(218, 218, 218);
    padding: 0.4em 1em;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }

  &__createAccount {
      margin: 2em 0;
  }

  &__ctaBtn {
    button {
      background-color: #2612b2;
      padding: 0.5em 1em;
      font-size: 0.95rem;
      color: rgb(236, 236, 236);
    }
  }

  @media only screen and (min-width: 768px) {
      &__title {
          margin: 2em 0;
      }
    &__siginContainer {
        height: 500px;
      margin: 6em auto;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
    }
  }
}
